@import '../heh-variables';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/buttons";

.btn{
  cursor:pointer;
  color: var(--white, $white);
  margin:0.5rem 4px;
  line-height:1.75;
  a{
    color: var(--white, $white);
  }
}
body > :not(#surveyor, .navbar, .footer) {
  .btn:not(.btn-outline-dark-scheme):not(.btn-outline-light-scheme) {
    border: 2px solid transparent !important; // So that regular buttons have same height as outlined buttons
  }
}

body > .footer {
  .btn.btn-light.mobile-button {
    border: 2px solid lightgray !important;
  }
}

.btn-outline-primary{
  color:  var(--primary-color, $primary-color);
  border: 1px solid var(--primary-color, $primary-color) !important;
  &:hover:not(.active){
    color:  var(--white, $white);
    background-color: var(--primary-color,$primary-color);
    opacity: 0.6;
    border-color:  var(--primary-color, $primary-color);
  }
}

.btn.btn-outline-dark-scheme {
  color: var(--white, $white) !important;
  border: 2px solid var(--white, $white) !important;
  background-color: transparent;

  &:hover{
    color: var(--white, $white) !important;
    border: 2px solid var(--white, $white) !important;
    background-color: transparent;
    opacity: 0.6;
  }
}

.btn.btn-outline-light-scheme{
  color:  var(--primary-color, $primary-color) !important;
  border: 2px solid var(--primary-color, $primary-color) !important;
  background-color: transparent;

  &:hover{
    color:  var(--primary-color, $primary-color) !important;
    border: 2px solid var(--primary-color, $primary-color) !important;
    background-color: transparent;
    opacity: 0.6;
  }
}

.btn-primary, a.btn-primary{
  background: var(--gradient-color, $gradient-color);
  background-color: var(--primary-color, $primary-color);
  border:none;
  color: var(--white, $white);
  a{
    color: var(--white, $white);
    &:hover{
      color: var(--white, $white);
    }
  }
  span{
    a{
      color: var(--white, $white);
      &:hover{
        color: var(--white, $white);
      }
    }
  }

  &:hover{
    background: var(--gradient-color-hover, $gradient-color-hover);
    background-color: var(--primary-color-hover, $primary-color-hover);
    border:none;
  }
}

a.btn-primary:focus, a.btn-primary:active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle{
  background-color: var(--primary-color, $primary-color);
  border:none;
  color: var(--white, $white);
}

.btn-main-color{
  background: var(--gradient-color, $gradient-color);
  background-color: var(--main-color, $main-color);
  border:none;
  color: var(--white, $white);
  span{
    a{
      color: var(--white, $white);
    }
  }
  a:hover{
    color: var(--white, $white);
  }
  &:hover{
    background: var(--gradient-color-hover, $gradient-color-hover);
    background-color: var(--main-color-hover, $main-color-hover);
    border:none;
  }
}

.btn-light{
  background-color: var(--light-bg, $light-bg);
  color: var(--primary-text-color, $primary-text-color);
  border:none;
  a{
    color: var(--primary-text-color, $primary-text-color);
  }
  &:hover{
    background-color: var(--light-bg-hover, $light-bg-hover);
    border:none;
  }
}
.btn-light-primary-text{
  background-color: var(--light-bg, $light-bg);
  color: var(--primary-color, $primary-text-color);
  a{
    color: var(--primary-color, $primary-text-color);
  }
  &:hover{
    background-color: var(--light-bg-hover, $light-bg-hover);
    border:none;
  }
}
.btn-xxl{
  padding-right: 60px;
  padding-left: 60px;
  font-size: xx-large;
  @media screen and (max-width: 489px){
    padding: 0.25rem 1rem;
    font-size: x-large;
  }
}

.btn-xl{
  padding-right: 30px;
  padding-left: 30px;
  font-size: x-large;
}

.btn-lg{
  padding: 0.7rem 0.75rem;
  font-size:1.5rem;
}

.btn-md{
  padding: 0.5rem 1rem;
  font-size:1.2rem;
}

.btn-secondary{
  background-color: var(--secondary-color, $secondary-color);
  &:hover{
    background-color:var(--secondary-color-hover,$secondary-color-hover);
  }
}

.btn-outline-secondary{
  border-color: $secondary-color;
  color: $secondary-color;
}

.btn-eureka-secondary{
  background-color: $eureka-dark;
  &:hover{
    background-color: rgba($eureka-dark, 0.9);
    color: $white;
  }
}

.dark-scheme-mobile-btn{
  background-color: #E6E6E6;
  color: $eureka-dark;
  &:hover{
    background-color: #E6E6E6E0;
  }
}

.btn-eureka-primary{
  background-color: $eureka-turq;
  &:hover{
    background-color: rgba($eureka-turq, 0.9);
    color: $white;
  }
}

a.btn.btn-main-color:not([href]):not([tabindex]), a.btn.btn-primary-color:not([href]):not([tabindex]){
  color: var(--white, $white);
}


.btn-lg{
  padding: 0.7rem 1rem;
}

.button_to{
  display:inline-block;
}


/* radio buttons that look like buttons */
label.btn.btn-secondary{
  background-color:rgba($secondary-color, .8);
}

.card a.btn.btn-primary{
  &:hover{
    color: var(--white, $white);
  }
}

.btn.disabled, .profile_boxfoot.disabled{
  background-color:$floating-window-border;
  border-color:$floating-window-border;
  color:$primary-text-color;
}

.btn.download-app{
  min-height:60px;
  img, svg {
    margin: 0 auto;
  }
}

.btn-outline-light:hover{
  background-color:rgba($white, .3);
}

@media screen and (max-width: 489px){
  a.btn.btn-sm.btn-outline-primary.float-right{
    float:none!important;
    display:block;
    margin-top: 1rem;
    margin-right:0.5rem;
  }
  .btn-xxl{
    padding-right: 30px;
    padding-left: 30px;
  }
  .btn:not(.block-exempt), a > .btn, .button_to, #forgot-password, .inline-link, #surveyor-submit, .next_section {
    display:block;
    margin-left: auto;
    margin-right: auto;
    width:100%;
    text-align:center;
  }
  .btn.btn-webview {
    width: unset;
  }
  .btn-lg, .btn-xl, .btn-xxl{
    padding: 0.5rem 0.75rem;
    font-size: 1.25rem;
  }
}

/* workaround to override bootstrap 2 with bootstrap 4
   so we can use Buttons::ButtonComponent in
   legacy frontend system - Please use sparsely
 */
.btn.btn4 {
  background-image: none;
  @extend .btn;
}
.btn.btn4.btn-main-color {
  @extend .btn-main-color;
}
