/*** Kickoff tiles ***/
.kickoffcenter {
  flex: 1 1 150px;
  min-width: 150px;
  max-width: 450px;
  margin: 0 1px;
}

a.section_link.pops{
  overflow: hidden;
  min-height: 200px;
  width: 100%;
  position: relative;
  margin: 10px 4px 0;
  padding: 0;
}

.panccyst,
.egene {
  #evtn_01,
  #evtn_02,
  #evtn_03,
  #evtn_04,
  #evtn_05,
  #evtn_06 {
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
  }
}

.survey_tn img {
  overflow:hidden;
  min-height: 200px;
  max-height: 205px;
}

div#evtn_01.plain{
  background: none;
  background-image: none;
  #pie {
    circle {
      stroke: var(--primary-text-color, #000);
    }
    text {
      fill: var(--primary-text-color, #000);
    }
  }
}

.solars.kickoff {
  hr, .kickoffcenter {
    display: none;
  }
  p {
    font-size: 1.05rem !important;
    font-weight: 500;
    padding-top: 1rem;
  }
  h2 {
    font-weight: 400;
  }
  .container-2:nth-child(2){
    .floating-window {
      padding-top: 10rem;
      .clearfix:nth-of-type(2){
        display: none;
      }
      .clearfix:nth-of-type(3) .row {
        padding-top: 0px;
        padding-bottom: 10rem;
      }
    }
  }
}
