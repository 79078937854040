@import "../../../../engines/surveyor/lib/assets/stylesheets/surveyor/reset.css";
@import "../../../../engines/surveyor/lib/assets/stylesheets/surveyor/jquery-ui-1.10.0.custom";
@import "../../../../engines/surveyor/lib/assets/stylesheets/surveyor/jquery-ui-timepicker-addon";
@import "../../../../engines/surveyor/lib/assets/stylesheets/surveyor/ui.slider.extras";
@import "../../../../engines/surveyor/lib/assets/stylesheets/surveyor/validations";
@import "../../../../engines/surveyor/lib/assets/stylesheets/surveyor";

#surveyor {
  width: unset;
  padding: unset;
  font-family:'Open Sans', sans-serif;
  background-color: #FFF;

  // Style for youtube embedded videos
  iframe[src*="youtube.com"] {
    width: 75vw;
    max-width: 100%;
    max-height: 486px;
    height: calc(75vw * 9 / 16); /* 16:9 aspect ratio */
  }
}
#survey_form {
  p.surveyinfo{
    font-size:1rem;
    line-height: 1.4rem;
    margin-bottom:0.5rem;
  }
  img{
    @extend .img-fluid;
  }
  b{
    font-weight:800;
  }
  div fieldset ol > span.help:first-child{
    width: 100%;
    padding: 0 0 0.7rem;
    font-size: 0.9rem;
    font-weight: 600;
  }
  input[type="checkbox"], input[type="radio"]{
    visibility:hidden;
    position:absolute;
  }
  fieldset {
    input{
      margin:0;
    }
    ol {
      display:flex;
      flex-wrap: wrap;
      li:not(.quiet) {
        flex-basis: calc(50% - 1rem);
        margin: 0 0.5rem;
        @media screen and (max-width: 767px){
          flex-basis: 100%;
          margin:0;
        }
      }
    }
    .surveyor_check_boxes.input.optional{
      li.choice {
        flex-basis: 100%;
        padding:0;
        margin: -0.5rem 0;
        label{
          width: 100%;
          margin: 0;
          input[type="checkbox"] + span{
            white-space: unset !important;
            position: relative;
            padding: 0.375rem 0.75rem 0.375rem 1.8rem!important;
            line-height:1.3rem;
            text-align:left;
            display:block!important;
            @extend .btn;
            @extend .btn-outline-primary;
            &:active{
              @extend .btn-primary;
            }
            &:before{
              position: absolute;
              font-family: 'Font Awesome 5 Free';
              left: 0.5rem;
              content: "\f111";
            }
          }
          input[type="checkbox"]:checked + span{
            white-space: unset;
            position: relative;
            padding: 0.375rem 0.75rem 0.375rem 1.8rem;
            @extend .btn-primary;
            &:before{
              position: absolute;
              font-family: 'Font Awesome 5 Free';
              left: 0.5rem;
              content: "\f058";
            }
          }
          span
            audio{
              display: block;
              margin-top: 0.5rem;
              width: 98% !important;
            }
        }
      }
    }
    .surveyor_radio.input/*.required*/{
      .choices-group li.choice {
        flex-basis: 100%;
        padding:0;
        margin: -0.5rem 0;
        label {
          width: 100%;
          white-space: unset !important;
          padding:0.6rem;
          line-height:1.3rem;
          @extend .btn;
          @extend .btn-outline-primary;
          &.active, &:active {
            @extend .btn-primary;
          }
        }
      }
    }
  }
  legend{
    font-size: 1.2rem;
    font-weight: bolder;
    line-height: 1.6rem;
    margin-bottom:0;
    span.qnum{
      display:none;
    }
  }
  .btn_yndk{
    flex-basis: 33%;
    @media screen and (max-width: 767px){
      flex-basis: 100%;
    }
    .surveyor_radio{
      flex-basis:100%;
    }
  }
  input[type=submit]{
    padding:0.4rem 1rem;
    @extend .btn;
    @extend .btn-main-color;
  }
  fieldset input[type="text"], fieldset input[type="number"]{
    font-size:1rem;
    @extend .form-control-lg;
    @extend .form-control;
  }
  select{
    height:40px;
    @extend .form-control;
    @extend .custom-select;
  }
  /* Details that are inside buttons */
  label button.surveydetail{
    background: $white;
    color: $primary-color;
    text-align:center;
    margin: 0.5rem auto 0.5rem;
    padding: 0 0.5rem;
  }
  button.surveydetail{
    background:none;
    padding:0;
    margin-bottom:0;
    display:block;
    span{
      color:$primary-color;
      transition: color 0.5s ease;
      &:hover{
        color:$primary-color-hover;
        transition: color 0.5s ease;
      }
      &::before{
        content:"- Hide detail";
      }
    }
  }
  button.surveydetail.collapsed span{
    &::before{
      content:"+ Get more detail";
    }
  }
  fieldset.q_dependent, fieldset.q_dependent legend{
    background: none;
  }
  .next_section{
    float:left;
  }
}

.ui-datepicker{
  background: $white;
}

li[id$="date_value_input"]{
  position:relative;
  &::before{
    position: absolute;
    font-family: 'Font Awesome 5 Free';
    top: 0.7rem;
    right: 2rem;
    content: "\f073";
    opacity: 0.5;
  }
}

/*spin box*/
input[type="number"]{
  -moz-appearance:textfield;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button{
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Slider */
#surveyor fieldset.q_slider {
  padding-top: 3em;

  .ui-slider ol.ui-slider-scale li:first-child span.ui-slider-label-show {
    margin-left: -1em !important;
  }

  ol li.select {
    flex-basis: calc(90%);
  }
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  background: black;
  color: white;
}
.ui-datepicker-prev {
  text-align: center;
  &:before{
    font-size: 1.4rem;
    font-family: 'Font Awesome 5 Free';
    content: '\f104';
  }
}
.ui-datepicker-next {
  text-align: center;
  &:before{
    font-size: 1.4rem;
    font-family: 'Font Awesome 5 Free';
    content: '\f105';
  }
}

.scaled-mobile-image > legend > span > img {
  width: 70%;
  margin-left: 15%;
}

#survey_form fieldset.singlecolumn{
  ol {
    display:flex;
    flex-wrap: wrap;
    li:not(.quiet) {
      flex-basis: 100%;
      margin-right: 50%;
      @media screen and (max-width: 767px){
        margin: 0;
      }
    }
  }
}
.custom_copyright_footer > legend > span{
  font-size: 75%;
}

body#surveyor-pdf {
  margin-top: 112px;

  #surveyor, .container-2 {
    background: none;
  }

  .pdf_question {
    page-break-inside: avoid;
  }

  #logospot img {
    max-width: 200px;
    max-height: 100px;
    margin-bottom: 1em;
  }
}
