@import '../heh-variables';

.cursor-default, .cursor-default.btn:not(:disabled):not(.disabled) {
  cursor: default;
}
.cursor-default.btn-main-color:hover, .cursor-default.btn:not(:disabled):not(.disabled).btn-main-color:hover {
  background-color: var(--main-color, $main-color);
  border-color: var(--main-color, $main-color);
}
.cursor-crosshair {
  cursor: crosshair;
}
.cursor-help {
  cursor: help;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-text {
  cursor: text;
}
.cursor-wait {
  cursor: wait;
}
