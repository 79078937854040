@import '../assets/stylesheets/b4/heh-variables';

@mixin checkmark($width) {
  .checkmark-#{$width} {
    .css-checkmark-container {
      display:inline-block;
      width: $width;
      height: $width;
      -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
      transform: rotate(45deg);

      .checkmark_circle {
        position: absolute;
        width:$width;
        height:$width;
        background-color: #fff;
        border-radius: calc(#{$width} + 1px);
        border-style: solid;
        border-width: calc(#{$width} / 21);
        border-color: var(--primary-color, $primary-color);
        left:0;
        top:0;
      }

      .checkmark_stem {
        position: absolute;
        width: calc(#{$width} / 21);
        height: calc((#{$width} / 2) - 3px);
        background-color: var(--primary-color, $primary-color);
        left: calc(#{$width} / 2);
        top: calc(#{$width} / 4 );
      }

      .checkmark_kick {
        position: absolute;
        width: calc(#{$width} / 6);
        height: calc(#{$width} / 21);
        background-color: var(--primary-color, $primary-color);
        left: calc((#{$width} / 2) - ((#{$width} + 2px) / 8));
        top: calc((#{$width} / 2) + ((#{$width} - 4px) / 5.6 ));
      }
    }
  }
}

@include checkmark(84px);
@include checkmark(42px);
