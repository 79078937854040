.seal-component {
  .row.top-seal.no-gutters {
    .small-font {
      font-size: 12px;
      font-weight: 600;
      line-height:1.2;
    }
    img{
      max-width:22%;
    }
  }
  @media screen and (max-width:767px){
    text-align:center;
    .top-seal{
      justify-content:center;
    }
  }
}
