@import '../../heh-variables';

/*** form elements / progress ***/

.form-control:focus{
  border-color: var(--primary-color, $primary-color);
  color: var(--form-placeholder-color,$form-placeholder-color);
}

.form-control::placeholder{
  color: var(--form-placeholder-color,$form-placeholder-color);
  opacity: 1;
}


