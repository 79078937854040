.testimonials-card-component {
  .contrast-color{
    color: #414D53;
  }
  h2 {
    font-size: 36px;
    font-weight: 400;
    line-height: 1.4em;
    @media only screen and (max-width:991px){
      font-size: 30px;
    }
  }
  .excerpt-text {
    font-weight: 400;
    line-height: 1.4em;
  }
  .card {
    background: #F7F8F9;
    max-height: 448px;
    .excerpt{
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }
  .card.d-lg-block {
    .excerpt {
      min-height: 430px;
    }
  }
  .card.d-sm-block, .card-d-md-block {
    .excerpt {
      min-height: 292px;
    }
  }
  .testimonial {
    .card-text {
      @media only screen and (min-width:576px){
        max-height: 300px;
      }
    }
  }
  .line-clamp {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    @media only screen and (min-width:576px){
      overflow: hidden;
    }
  }
  // For Design::Component::Sections::Testimonials
  .testimonials-lg-content {
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    @media only screen and (min-width:576px){
      overflow: hidden;
    }
  }
}
