@import '../heh-variables';

.carouselLanding{
  .carousel{
    width:100%;
  }

  .carousel-caption{
    text-align: left;
    left: 10%;
    top: 10%;

    h3{
      font-weight:100;
      text-shadow:1px 1px 1px rgba($black, .65);
    }
  }
}

.carouselHome{
  border-radius:4px;
  border:1px solid #E2E3E6;
  max-width:100%;
  height:auto;
  margin:0 auto;

  .carousel-item > img{
    opacity: 0.6;
    object-fit: cover;
    width:100%;
  }

  .carousel-background{
    background: var(--black, $black);
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .carousel-caption2, .carousel-caption3, .carousel-app-only{
    position:absolute;
    color: var(--white, $white);
    top: 8%;
  }

  .carousel-caption2{
    left: 5%;
    text-align:left;
  }

  .carousel-caption3{
    right: 5%;
    text-align:right;
  }

  .carousel-app-only{
    display: none;
    top: 12%;
    left: calc((100% - 207px)/2);
    text-align: left;
    width: 315px;
    .wrapper {
      position: relative;
      left: 0;
      min-width: 250px;
    }
    .wrapper {
      span:first-child {
        float: left;
        width: 30%;
        @media screen and (max-width: 767px){
          position: relative;
          top: 1.5rem;
          left: 1rem;
        }
      }
      p, h4 {
        position: relative;
        left: -10px;
        top: 5px;
        @media screen and (max-width: 767px){
          left: -0.5rem;
          top: 2rem;
        }
      }
    }
  }
  .app-only .carousel-app-only{
    display: block;
  }
  .app-only.carousel-item {
    @media screen and (max-width: 767px){
      .carousel-caption {
        p {
          display: none;
        }
        h2 {
          position: relative;
          top: -2rem;
        }
      }
    }
  }
}

.carousel.interstitial {
  margin-top: 10px;
  .carousel-caption {
    bottom: -5px;
    left: 0;
    right: 0;
  }
  .carousel-caption3{
    top: 2%;
  }
  .carousel-inner {
    height: 300px;
  }
  .carousel-item { top: 0; }
  .carousel-item > img {
    width: 100%;
    object-fit: cover;
  }
}

.overlay2{
  background: linear-gradient(to bottom, transparent 0%, rgba($black, .75) 100%);
  text-align: left;
  bottom: 0;
  width: 100%;
  min-height: 200px;
  position:absolute;

  > .kickoff-tile-label{
    position:absolute;
    bottom:.5rem;
    padding:0 .75rem;
  }
}

.overlay2.overlay-light{
  background: rgba($white, .6);
}

/* for completed kickoff tiles in follow up */
.overlay2.greenoverlay{
  background:rgba($green, .75);
  top:0;
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.covid19 .carouselHome {
  border: none;
}
