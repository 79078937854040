.pt-3 {
  padding-top: 3rem;
}
.pt-5 {
  padding-top: 5rem;
}

.pt-6 {
  padding-top: 6rem;
}

.pt-7 {
  padding-top: 7rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

.pb-6 {
  padding-bottom: 6rem;
}

.pb-7 {
  padding-bottom: 7rem;
}

.mt-3 {
  margin-top: 3rem;
}
.mt-5 {
  margin-top: 5rem;
}

.mt-6 {
  margin-top: 6rem;
}

.mt-7 {
  margin-top: 7rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.mb-6 {
  margin-bottom: 6rem;
}

.mb-7 {
  margin-bottom: 7rem;
}


