@import "../heh-variables";
/* Pledge page svgs */

svg.screens{
  cursor:pointer;
  circle{
    fill: var(--primary-color, $primary-color);
    transition: fill 0.5s ease;
  }
  rect{
    fill: var(--primary-color, $primary-color);
    transition: fill 0.5s ease;
  }
  &:hover{
    circle{
      fill: var(--main-color, $main-color);
      transition: fill 0.5s ease;
    }
    rect{
      fill: var(--main-color, $main-color);
      transition: fill 0.5s ease;
    }
  }
}

svg.smile{
  cursor:pointer;
  ellipse{
    fill: var(--primary-color, $primary-color);
    transition: fill 0.5s ease;
  }
  use{
    stroke: var(--primary-color, $primary-color);
    transition: stroke 0.5s ease;
  }
  &:hover{
    ellipse{
      fill: var(--main-color, $main-color);
      transition: fill 0.5s ease;
    }
    use{
      stroke: var(--main-color, $main-color);
      transition: stroke 0.5s ease;
    }
  }
}

svg.lock{
  cursor:pointer;
  circle{
    fill: var(--primary-color, $primary-color);
    transition: fill 0.5s ease;
  }
  path:first-child{
    stroke: var(--primary-color, $primary-color);
    transition: stroke 0.5s ease;
  }
  &:hover{
    circle{
      fill: var(--main-color, $main-color);
      transition: fill 0.5s ease;
    }
    path:first-child{
      stroke: var(--main-color, $main-color);
      transition: stroke 0.5s ease;
    }
  }
}
