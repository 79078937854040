@import '../assets/stylesheets/b4/heh-variables';

@mixin crossmark($width) {
  .crossmark-#{$width} {
    .css-crossmark-container {
      display:inline-block;
      position: relative;
      height: $width;
      width: $width;
      flex-direction: column;
      justify-content: center;

      &:before,
      &:after {
        position: absolute;
        content: '';
        top: calc((#{$width} / 2) - 2px);
        left: calc((#{$width} / 4));
        width: 50%;
        height: 4px; /* cross thickness */
        background-color: coral;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
      .crossmark-circle {
        position: absolute;
        width:$width;
        height:$width;
        border-radius: calc(#{$width} + 1px);
        border-style: solid;
        border-width: calc(#{$width} / 21);
        border-color: coral;
        left:0;
        top:0;
      }
    }
  }
}

@include crossmark(128px);
@include crossmark(84px);
@include crossmark(64px);
@include crossmark(42px);
@include crossmark(24px);
