@import '../heh-variables';

.hipaa-tile{
  p:last-child{
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-block;
    @extend .align-self-end;
    @extend .mb-3;
  }
  .profile_boxhead{
    p:last-child{
      display:none;
    }
  }
}

.profile_items.connected_devices, .profile_items.connected_apps{
  @extend .row;
  > div{
    @extend .col-lg-4;
    @extend .col-md-6;
  }
  .btn{
    @extend .mb-3;
  }
}

.profile_item_empty {
  color: var(--primary-text-color, $primary-text-color);
}

#human_api_tiles{
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  #connect-medical-records-data, #connect-wellness-data{
    @extend .col-lg-4;
    @extend .col-md-6;
    #connect-medical-records-data-btn, #connect-wellness-data-btn{
      @extend .card;
      @extend .my-3;
      @extend .min-tile-height;
      background-color: var(--light-bg, $light-bg);
      padding: 1.25rem;
      .profile_boxfoot{
        @extend .btn;
        @extend .btn-primary;
        @extend .btn-block;
        position:absolute;
        bottom:10px;
        max-width: 90%;
        left: 5%;
        right: 5%;

      }
      .profile_item_fitbit{
        color: var(--primary-text-color, $primary-text-color);
        p:first-child{
          @extend .text-muted;
          @extend .text-uppercase;
          @extend .font-weight-bold;
          @extend .small-text;
          @extend .mt-2;
        }
        p:last-child{
          min-height:80px;
        }
      }
    }
  }
}

.hipaa-tile{
  p:last-child{
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-block;
    @extend .align-self-end;
    @extend .mb-3;
  }
  .profile_boxhead{
    p:last-child{
      display:none;
    }
  }
}

.profile_items.connected_devices, .profile_items.connected_apps{
  @extend .row;
  > div{
    @extend .col-lg-4;
    @extend .col-md-6;
  }
  .btn{
    @extend .mb-3;
  }
}

.profile_item_empty{
  color: var(--primary-text-color, $primary-text-color);
}

