@import '../heh-variables';

#account-dropdown::after {
  display: none;
}
.color-scheme-bg {
  background-color: var(--scheme-color, $primary-color) !important;
}
.color-scheme-text {
  color: var(--scheme-text-color, white) !important;
}
.bg-eureka-dark {
  background-color: $eureka-dark !important;
}
.text-eureka-dark {
  color: $eureka-dark !important;
}
.nav-item-hover-effect-before {
  content:" ";
  width:100%;
  height:2px;
  position:absolute;
  bottom:0px;
  background-color: var(--primary-color, $primary-color);
  visibility:hidden;
  transform:scaleX(0);
  transition:all 0.1s ease-in 0.1s;
}
.secondary-nav-item-hover-effect-before {
  content: " ";
  position: absolute;
  height: 7px;
  width: calc(100% + 20px);
  left: -10px;
  bottom: -20.5px;
  border-radius: 10px 10px 0 0;
  background-color: var(--scheme-text-color, white);
  visibility:hidden;
  transform:scaleX(0);
  transition:all 0.1s ease-in 0.1s;
}
.study-web-banner-nav-item-hover-effect-before {
  content: " ";
  position: absolute;
  height: 7px;
  width: calc(100% + 20px);
  left: -10px;
  bottom: -28px;
  border-radius: 10px 10px 0 0;
  background-color: var(--scheme-text-color, white);
  visibility:hidden;
  transform:scaleX(0);
  transition:all 0.1s ease-in 0.1s;
}

.nav-item-hover-effect-before-hover {
  // Height changes on smaller screens, unless we want to have rules for each breakpoint, we should just hide this effect
  @media screen and (min-width: 768px){
    visibility: visible !important;
    transform: scaleX(1) !important;
  }
}

// Different hover effects
nav.navbar.home-nav{
  .navbar-nav:not(.mr-auto) {
    li.nav-item{
      color: var(--eureka-gray, #7D888D);
      a:not(.dropdown-item), form > button:not(.logout-button){
        &:hover {
          color: var(--nav-links, $nav-links);
        }
        &:before {
          @extend .nav-item-hover-effect-before;
        }
        &:hover:before{
          @extend .nav-item-hover-effect-before-hover;
        }
      }
      .logout-button {
        &:hover {
          color: var(--nav-links, $nav-links);
        }
      }
    }
  }
}
nav.navbar.secondary-nav, nav.navbar.landing-nav{
  .navbar-nav:not(.mr-auto) {
    li.nav-item{
      a:not(.dropdown-item), form > button{
        display: block;
        width: 100%;
        &:hover {
          color: var(--scheme-text-color, white);;
        }
        &:before {
          @extend .secondary-nav-item-hover-effect-before;
        }
        &:hover:before{
          @extend .nav-item-hover-effect-before-hover;
        }
      }
    }
  }
}

.landing.study nav.navbar li.nav-item a.nav-study:before,
.landing.community nav.navbar li.nav-item a.nav-community:before,
.landing.team nav.navbar li.nav-item a.nav-team:before,
.landing.faqs nav.navbar li.nav-item a.nav-faqs:before,
.landing.contact nav.navbar li.nav-item a.nav-contact:before { // <-- May need to remove this rule since now we only have the index page but we don't want this to be always active
  @extend .nav-item-hover-effect-before-hover;
}

nav.navbar.secondary-nav > .container > ul > li.nav-item.active > a:before {
  @extend .nav-item-hover-effect-before-hover;
}

nav.navbar.secondary-nav {
  box-shadow: 0 .25rem .25rem rgba(0,0,0,.2)!important;
  height: 65px;
  a:not(.dropdown-item) {
    color: var(--scheme-text-color, white);
    font-weight: 600;
    font-size: 16px !important
  }
  img.secondary-web-banner {
    max-width: 170px;
    max-height: 50px;
  }
  .container {
    height: 65px;
  }
}
// Horizontal scroll
@media screen and (max-width: 991px) {
  nav.navbar.secondary-nav::-webkit-scrollbar, nav.navbar.web-banner-nav::-webkit-scrollbar {
    display: none;
  }
  nav.navbar.secondary-nav, nav.navbar.web-banner-nav {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  nav.navbar.secondary-nav, nav.navbar.web-banner-nav {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 1rem !important;
    .container {
      .navbar-nav {
        flex-direction: row;
        white-space: nowrap;
        padding-top: 0px !important;
        .nav-item.active {
          a, span {
            font-weight: 700;
          }
        }
      }
    }
  }
}

nav.navbar:not(.transition-nav){
  .navbar-nav:not(.mr-auto) {
    li.nav-item{
      line-height:24px;
      a:not(.dropdown-item), form > button{
        text-align:right;
        position:relative;
      }
    }
  }
  .dropdown-menu{
    min-width: unset;
    .dropdown-item.active{
      background-color: var(--primary-color, $primary-color);
    }
  }
  .btn{
    margin:0 15px;
    span{
      padding:0;
    }
  }
  .navbar-brand{
    img{
      max-height: 60px;
      max-width:220px;
    }
  }
  .navbar-brand.full-logo{
    img{
      max-width:220px;
      padding: 0.2rem 0;
    }
  }
  //custom toggle nav
  @media screen and (max-width: 767px){
    padding:0;
    height:76px;
    .container{
      max-width: 100%;
      .navbar-brand, .navbar-toggler{
        margin:6px 12px;
      }
      ul:not(.mr-auto){
        text-align: center;
        font-weight:600;
        font-size:1.1rem;
        padding-top:3%;
      }
      .form-inline{
        margin: 10px auto;
      }
    }
  }
  @media screen and (max-width: 992px) {
    /*By default, containers are only full-width on xs screens.
    This makes them full-width om sm screens as well*/
    .container {
      margin-left: 0;
      margin-right: 0;
      max-width: 100% !important;
      background: #FFF;
    }
    .collapse.show{
      height:100vh;
    }
    .collapsing li, .collapsing .form-inline{
      display:none
    }
    .collapse.show li, .collapse.show .form-inline{
      animation:navfade .5s
    }
  }
}

nav.navbar.secondary-nav {
  box-shadow: 0 .25rem .25rem rgba(0,0,0,.2)!important;
}

nav.navbar:not(.transition-nav, .secondary-nav, .web-banner-nav){
  background: #FFF !important;
  @media screen and (max-width: 767px){
    z-index: 2;
  }
  @media screen and (max-width: 992px) {
    .container {
      z-index: 2;
    }
  }
}

.home-nav, .landing-nav{
  .container{
    li.nav-item{
      a:not(.dropdown-item), span, button:not(.logout-button) {
        color: var(--eureka-gray, #7D888D);
        font-weight: 600;
        font-size: 14px !important
      }
    }
  }
}

.user.landing {
  .user-study-info-divider {
    display: block !important;
  }
  .border-dark {
    border-color: $eureka-dark !important;
  }
}

.user:not(.landing) nav.navbar.web-banner-nav{
  box-shadow: 0 .25rem .25rem rgba(0,0,0,.2)!important;
}
.user.landing .secondary-join-now {
  display: none !important;
}

nav.navbar.web-banner-nav {
  height: 80px !important;
  .navbar-nav.web-banner-nav-links {
    li.nav-item{
      a:not(.dropdown-item), form > button{
        color: var(--scheme-text-color, white);
        font-weight: 600;
        font-size: 16px !important;
        display: block;
        width: 100%;
        &:hover {
          color: var(--scheme-text-color, white);
        }
        &:before {
          @extend .study-web-banner-nav-item-hover-effect-before;
        }
        &:hover:before{
          @extend .nav-item-hover-effect-before-hover;
        }
      }
    }
    li.nav-item.active {
      a:not(.dropdown-item) {
        &:before{
          @extend .nav-item-hover-effect-before-hover;
        }
      }
    }
  }
}

.container-2{
  nav.navbar{
    @media screen and (max-width: 767px){
      height:auto;
      z-index:0;
      padding: 0.5rem 1rem;
    }
  }
}

@keyframes navfade{
  from {
    opacity:0
  }
  to {
    opacity:1
  }
}

.navbar-expand-md, .navbar-expand-lg{
  height:65px;
}

.transition-nav {
  .nav-link.dropdown-toggle {
    color: var(--scheme-text-color, white) !important;
  }
  .dropdown-menu {
    position: absolute;
  }
}

.container-2{
  .navbar-brand{
    margin-right:0;
  }
}

.web-banner{
  height: 50px;
}
.landing{
  .web-banner-container{
    height: 100px;
    padding-top: 1.5rem; // pt-4
  }
}

.panccyst nav.navbar{
  .navbar-brand img{
    max-width:100%;
    max-height: inherit;
  }
}

.egene nav.navbar{
  .navbar-brand img{
    max-width:100%;
    max-height: 70px;
  }
}

.enet nav.navbar {
  .navbar-brand{
    img{
      max-height: 80px;
    }
  }
}

.kinlist-research nav.navbar {
  .navbar-brand.full-logo img{
    max-height: 64px;
  }
}

.eatmovesleep {
  nav.navbar {
    li.nav-item {
      a {
        &:before {
          background-color: var(--nav-links, $nav-links);
        }
        &:hover {
          color: var(--nav-links, $nav-links);
        }
        &:hover:before{
          background-color: var(--nav-links, $nav-links);
        }
      }
    }
  }
}

// Secondary navbar animations
@keyframes fade-in-animation {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
@keyframes fade-out-animation {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}
.secondary-web-banner, .secondary-join-now {
  display: none;
}
.fade-in {
  display: block;
  animation-name: fade-in-animation;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

.fade-out {
  animation-name: fade-in-animation;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

.secondary-nav-links {
  padding-left: 0;
  transition: padding-left 1s;
}
.secondary-nav-links.move-right {
  padding-left: 195px;
}

// Transiiton layout css
%nav.navbar.transition-nav {
  max-height: 74px;
}

.transition-eureka-logo-spacer-bg-light {
  border-style: solid;
  border-width: 5.1em 20px 0 0;
  border-color: white var(--scheme-color, --primary-color) transparent transparent;
}
.transition-eureka-logo-spacer-bg-dark {
  border-style: solid;
  border-width: 5.1em 20px 0 0;
  border-color: $eureka-dark var(--scheme-color, --primary-color) transparent transparent;
}
.eureka-logo-container {
  height: 5.1em;
}
