@import '../heh-variables';

.consents-approvals {
  .instruction {
    margin-bottom: 2em;
  }
}
// Eat Move Sleep
.eatmovesleep.consents-approvals {
  .instruction .main-color {
    color: var(--primary-color, $primary-color);
  }
}

.eatmovesleep.docusign-preconsents {
  .instruction {
    .main-color i {
      color: var(--primary-color, $primary-color);
    }
  }
}
