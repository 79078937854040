@import '../heh-variables';

// EatMoveSleep study
.eatmovesleep.activity-assignments.show {
  h3 {
    font-size: 30px;
  }

  p {
    font-size: 18px;
  }

  h3, p a {
    font-weight: 700;
  }

  a.fitbit-link {
    font-weight: normal !important;
  }

  p a {
    color: var(--main-color, $main-color);

    &:hover {
      color: var(--main-color-hover, $main-color-hover);
    }
  }

  .btn-group {
    a {
      font-size: 25px;
      padding-left: 1.5em;
      padding-right: 1.5em;
    }
  }
}
