.kardia {
  #agreement {
    .section {
      border-bottom: 1px solid #ccc;

      &:last-of-type {
        border-bottom: none;
      }
    }

    a {
      font-weight: 600;
    }

    i { font-size: 3.0em; }

    i.fa-chart-line {
      color: #FAC069;
    }

    i.fa-chart-pie {
      color: #F2796C;
    }

    i.fa-user-shield {
      color: #927BE9;
    }
  }
}
