@import "~bootstrap/scss/card";
@import "~bootstrap/scss/images";

.card a.btn.btn-primary, .card-body a.btn.btn-primary{
  &:hover{
    color: var(--white, $white);
  }
}

#accordion .card, .accordion .card{
  margin:5px 0;

  .card-header{
    background-color:  var(--light-bg, $light-bg);
    border-bottom: 1px solid var(--primary-text-color, $primary-text-color) !important;

    h5{
      color: var(--primary-text-color, $primary-text-color);
      transition: color 0.5s ease;
      margin: 0.2rem 0 0 0;
      font-weight: 600;
      &:hover{
        color: var(--primary-color, $primary-color);
        transition: color 0.5s ease;
      }

      .card-header-icon-span{
        color: var(--primary-color, $primary-color);
      }
    }
  }
  .card-header:not(.collapsed) .card-header-icon-span .rotate-icon {
    transform: rotate(180deg);
  }
}

#accordion.faq .card, .accordion.faq .card{
  p{
    padding:1.25rem;
    margin-bottom:0;
  }
}

#accordion.study .card, .accordion.study .card{

  &:hover{
    border: 2px solid var(--primary-color, $primary-color);
  }

  .card-header{
    background-color:  var(--white, $white);

    h6{
      color: var(--primary-text-color, $primary-text-color);
      transition: color 0.5s ease;
      margin: 0.2rem 0 0 0;
      font-weight:bold;
      &:hover{
        color: var(--primary-color, $primary-color);
        transition: color 0.5s ease;
      }
    }
  }

  h4{
    padding:1.75rem;
    margin-bottom:0;
  }

  ul{
    margin-bottom:0;
  }

  p{
    padding:1.75rem;
    margin-top:0;
    margin-bottom:0;
  }

}

#mapbox > div.mapboxgl-canvas-container.mapboxgl-interactive.mapboxgl-touch-drag-pan.mapboxgl-touch-zoom-rotate > canvas {
  position: relative !important;
  width: 100% !important;
  height: 500px !important;
}

.card.profile_box{
  transition: all 0.5s ease;
  background-color: var(--light-bg, $light-bg);
  &:hover{
    border-color: var(--primary-color-hover, $primary-color-hover);
    background-color: var(--white, $white);
    transition: all 0.5s ease;
  }

  img{
    margin:0 auto;
    display:block;
    @extend .img-fluid;
  }

  h2, p{
    color: var(--primary-text-color, $primary-text-color);

    span{
      @extend .small-text;
      font-weight: 700;
      opacity: 0.65;
      &:before{
        content:" ";
      }
    }
  }

  .card-footer{
    background:transparent;
    border-top:none;
  }
}

.card.profile_box{
  .profile_item_medhistory{
    p:not(.plusmore):not(.add-measurement-link){
      font-size:1.3rem;
      margin-bottom:0.7rem;
    }
  }
}
