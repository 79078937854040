/* Video play button */

i.far.fa-play-circle{
  font-size:11rem;
  transition:all 0.2s ease-in-out 0.1s;

  &:hover{
    font-size:13rem;
    text-shadow: 2px 2px 3px rgba(255,255,255,0.1)
  }
}

a.btn-grey, span.btn-grey, button.btn-grey {
  background-color: $eureka-gray;
  border-color: $eureka-gray;
  color: $white;

  &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active {
    background-color: #AAA;
  }

  a:not(.btn):not(.btn.btn4):hover {
    color: $white;
  }

  a, a:hover {
    transition: none;
  }
}

@media screen and (max-width: 767px){
  i.far.fa-play-circle{
    font-size:24vw;
    padding:1rem 0;
    &:hover{
      font-size:26vw;
    }
  }
}
