@import '../../assets/stylesheets/b4/heh-variables';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/custom-forms";

 // most of these styles won't be needed once we upgrade to Bootstrap 4.4

$custom-control-gutter:                         .5rem !default;
$custom-control-label-color:                    null !default;
$custom-control-cursor:                         null !default;
$custom-control-indicator-size:                 1rem !default;
$custom-control-indicator-border-color:         $gray-500 !default;
$custom-control-indicator-border-width:         $input-border-width !default;
$custom-switch-width:                           $custom-control-indicator-size * 1.75 !default;
$custom-switch-indicator-border-radius:         $custom-control-indicator-size / 2 !default;

.custom-control-label {
  font-size: larger;
  position: relative;
  margin-bottom: 0;
  margin-left: 1rem;
  color: $custom-control-label-color;
  vertical-align: top;
  cursor: $custom-control-cursor;

   // Background-color and (when enabled) gradient
  &::before {
    position: absolute;
    top: ($font-size-base * $line-height-base - $custom-control-indicator-size) / 2;
    left: -($custom-control-gutter + $custom-control-indicator-size);
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    pointer-events: none;
    content: "";
    background-color: $custom-control-indicator-bg;
    border: $custom-control-indicator-border-color solid $custom-control-indicator-border-width;
    @include box-shadow($custom-control-indicator-box-shadow);
  }

   // Foreground (icon)
  &::after {
    position: absolute;
    top: ($font-size-base * $line-height-base - $custom-control-indicator-size) / 2;
    left: -($custom-control-gutter + $custom-control-indicator-size);
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    content: "";
    background: no-repeat 50% / #{$custom-control-indicator-bg-size};
  }
}

.custom-checkbox .custom-control-label:before{
  background-color: white;
  border: 1px solid gray;
}
.custom-control-input:disabled ~ .custom-control-label{
  cursor: not-allowed;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before
{
  background-color: var(--primary-color, $primary-color);
}
