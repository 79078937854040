.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--primary-color, red);
  background-color: var(--primary-color, red);
}
.custom-radio .custom-control-input:checked~.custom-control-label::before,
.custom-radio .custom-control-input:checked~.custom-control-label::after {
  border-color: var(--primary-color, red);
  background-color: var(--primary-color, red);
  border-radius: 50%;
}
