.social-share-buttons-component {
  background: inherit;
}
#social-share-buttons .btn, .social-share-button {
  @extend .btn-xl !optional;
  @extend .mx-0 !optional;
}
#social-share-buttons .btn:hover, .social-share-button:hover {
  color: $white;
}
