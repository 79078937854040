.bring-in {
  animation: bring-in 2s;
}

@keyframes bring-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
