@import '../../heh-variables';

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.progress-bar
{
  background-color: var(--primary-color, $primary-color);
}

input.custom-control-input:checked + span.custom-control-label
{
  font-weight:600;
}

