/*** Time to download app styles ***/

ol.decimal-list {
  list-style: decimal;
}

ol.decimal-list li {
  margin-left: 1em;
}

.download-message {
  max-width: 359px;
}
