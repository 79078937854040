@import '../heh-variables';
/* Study page specific */

.study-icons{
  font-size:6rem;
  color: var(--main-color, $main-color);
}

.study-icons.goal{
  color: var(--secondary-color, $secondary-color);
}

i.study-icons-blue{
  font-size:3rem;
  color: var(--primary-color, $primary-color);
  transition:all 0.2s ease-in-out 0.1s;
  &:hover{
    font-size:4rem;
  }
}

.circle{
  width: 58px;
  height: 58px;
  background-color:  var(--primary-color, $primary-color);
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  margin-right: 20px;
  float: left;

  .study-list{
    font-size: 36px;
    color: var(--white, $white);
    font-weight:bold;
    text-align: center;
  }
}

.circle.docusign-symbol{
  width: 103px;
  height: 103px;
  margin-right: 0;

  img{
    max-width: 60px;
    margin: 26px;
  }
}

.heh-pink{
  color: var(--main-color, $main-color);
}
