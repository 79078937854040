@import '../heh-variables';

.scroll{
  overflow:auto;
  max-height:400px;
  background: var(--white, $white);
}

.scroll-550{
  overflow:auto;
  max-height:500px;
  background: var(--white, $white);
}

.scroll-65vh{
  overflow:auto;
  max-height:65vh;
  background: var(--white, $white);
}


