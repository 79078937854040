/*** Responsive ***/

@media screen and (max-width: 991px){
  .display-name{
    display:none;
    top:0!important;
    margin-top:15px;
  }
  ul.navbar-nav.mt-3{
    margin-top:0!important;
  }
}

@media screen and (max-width: 767px){
  .carousel-caption2, .carousel-caption3{
    max-width:45%;
    top:5%!important;
  }
  .carouselLanding{
    display:none;
  }
  [class*="order"]{
    order:0;
  }
  .left-me{
    text-align:left!important;
    display:block;
    margin:0 auto;
  }
  .spacer{
    display:none;
  }
}

@media screen and (max-width: 767px) and (min-width: 490px){
  h1.hero{
    font-size:2.8rem;
    line-height:3rem;
  }
  h1{
    font-size:2.6rem;
    line-height:2.9rem;
  }
  h2{
    font-size:2rem;
    line-height:2.4rem;
  }
  h3{
    font-size:1.8rem;
    line-height:2.2rem;
  }
}

@media screen and (max-width: 575px){
  .activity-assignments{
    .card{
      text-align:center;
    }
    .canvas{
      text-align:center;
    }
  }
  .chart-col {
    margin-top: 1rem !important;
  }
  .chart-label-col {
    padding-top: 1rem !important;
  }
  .carousel.interstitial {
    .carousel-caption {
      bottom: 35px;
    }
    .carousel-item > img {
      width: auto;
    }
  }
}

@media screen and (max-width: 489px){
  .float-left:not(.block-exempt){
    float:none!important;
    display:block;
    margin-bottom: 1rem;
    margin-left:0.5rem;
  }
  .float-right:not(.btn.btn-sm.btn-outline-primary){
    float:none!important;
    display:block;
    margin-bottom: 1rem;
    margin-right:0;
  }
  .pledge{
    text-align:center;
    svg{
      display:inline;
    }
    .btn{
      padding: 0.6rem 0.2rem;
      font-size: 1.05rem;
    }
  }
  h1.hero{
    font-size:2.4rem;
    line-height:2.6rem;
    padding-top: 0;
  }
  h1{
    font-size:2.2rem;
    line-height:2.4rem;
  }
  h2{
    font-size:1.8rem;
    line-height:2.2rem;
  }
  h3{
    font-size:1.6rem;
  }
  h4{
    font-size:1.2rem;
  }
  h5{
    font-size:1.2rem;
  }
  .row:not(.keep-row-padding){
    padding:.75em 0;
  }

  .d-sm-inline-flex, .modal-footer{
    flex-direction:column-reverse;
  }
  .d-xs-inline-flex{
    flex-direction:column;
  }
  .connected-data-card-xs-text {
    font-size: 90%;
  }
}
.rtl{
  direction: rtl !important;
}
