.slider-component.carousel.slide{
  @media only screen and (min-width: 576px) and (max-width: 1490px) {
    width: 75%;
    .carousel-control-next {
      right: -10%;
    }
    .carousel-control-prev {
      left: -10%;
    }
    @media only screen and (min-width: 1200px) {
      .carousel-control-next {
        right: -15%;
      }
      .carousel-control-prev {
        left: -15%;
      }
    }
  }
  @media only screen and (min-width: 1490px){
    width: 90rem;
    .carousel-control-prev {
      padding-right: 100px;
    }
    .carousel-control-next {
      padding-left: 100px;
    }
  }

  .carousel-inner{
    max-height: 448px;
  }

  .carousel-indicators{
    li {
      width: 5px;
      height: 5px;
      border-radius: 2.5px;
      filter: invert(0.75);
    }
  }
  .carousel-control-next, .carousel-control-prev {
    .carousel-control-next-icon, .carousel-control-prev-icon {
      filter: invert(0.75);
    }
  }
}

