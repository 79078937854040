/* Footer */

.landing-footer{
  background: $eureka-gray-bg;
  color: var(--eureka-gray,$eureka-gray);
  padding:0.8rem 0;
  ul{
    list-style:none;
    padding:0;
  }
  img{
    margin:10px 0;
    max-height: 40px;
  }
  li a{
    color: var(--eureka-gray,$eureka-gray);
    &:hover{
      color:rgba($eureka-gray, .75);
    }
    font-size:.9rem;
  }
  .fa, .fab, .fas, .far, .fal, .fad{
    font-size:3.5rem;
    margin:0 8px 5px 0;
    color: var(--eureka-gray,$eureka-gray);
    &:hover{
      color:rgba($eureka-gray, .75);
    }
  }
}

footer.footer {
  p{
    font-size: .9rem;
  }
  .footer-column {
    max-width: fit-content;
    .list-wrapper {
      padding: 0;
    }
  }
  .dropdown {
    color: var(--eureka-gray, #7D888D);
    font-weight: 600;
    font-size: 14px !important;
    .dropdown-menu {
      min-width: unset;
      width: 8rem;
      .dropdown-item.active{
        background-color: var(--primary-color, $primary-color);
        color: white;
      }
    }
  }
}

footer.footer.global-footer{
  background: $eureka-gray-bg;
  color: var(--eureka-gray,$eureka-gray);
  img{
    max-height:30px;
    margin:0 10px 10px;
  }
  li{
    font-size: .9rem;
    padding:0.3rem 0;
    a{
      color: var(--eureka-gray,$eureka-gray);
      &:hover{
        color: var(--eureka-turq,$eureka-turq);
      }
    }
  }
  p{
    margin:0 10px 0;
    text-align:right;
  }
  sub{
    bottom:0;
  }
  @media screen and (min-width: 490px){
    ul{
      width: 100%;
      height: 130px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      li{
        flex-basis: 25%;
      }
    }
  }
}

footer.footer.surveyor-footer{
  display:none;
}
