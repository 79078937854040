.evisit_complete {
  h3 {
    font-weight: bold;
  }
  p {
    font-size: 1.1em;
  }
  a {
    font-size: 1.4em;
    padding: 0.4em 2em;
  }
}
