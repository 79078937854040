@import '../heh-variables';

.activity-assignments.show .omron-activity {
  h3 {
    font-weight: 700;
    margin-bottom: 0.75em;
  }

  p {
    font-size: 18px;
  }

  img {
    max-width: 100%;
  }
}
