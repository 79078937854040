/*** react/login page ***/
body.react-af.login {
  background: #FFF;
  .container {
    ul:not(.navbar-nav){
      list-style: unset;
      margin-left: 1.5em;
    }
    ol{
      list-style: decimal;
      margin-left: 1.5em;
    }
  }
}