@import '../heh-variables';

body:not(.transition) {
  .fill-with-footer, .fill-with-shared-footer {
    min-height:calc(100vh - #{$eureka-fill-with-footer});
    @media screen and (min-width: 684px) and (max-width: 945px) {
      min-height:calc(100vh - #{$panccyst-fill-with-footer-ipad});
    }
  }
  .my-fill-with-footer {
    min-height:calc(100vh - #{$my-fill-with-footer});
  }
}
body.transition {
  .fill-with-footer, .fill-with-shared-footer {
    min-height:calc(100vh - #{$transition-fill-with-footer});
  }
}

.heh .fill-with-footer {
  min-height:calc(100vh - #{$heh-fill-with-footer});
  @media screen and (min-width: 684px) and (max-width: 945px) {
    min-height:calc(100vh - #{$heh-fill-with-footer-ipad});
  }
}

.fill-without-footer {
  min-height:calc(100vh - #{$fill-without-footer});
}

// use this only for special views like integration callback views in mobile devies
.fill-without-anything {
  min-height:calc(100vh);
}

.vh100-landing{
  min-height:calc(100vh - 80px);
  @media screen and (max-width:767px){
    text-align:center;
    .top-seal{
      justify-content:center;
    }
  }

}
