@import '../heh-variables';

/*** Landing pages ***/
.bg-primary-color {
  background-color: var(--primary-color, $primary-color);
}
.landing {
  background: #FFF;
  .container {
    ul:not(.navbar-nav, .list-unstyled) {
      list-style: unset;
      margin-left: 1.5em;
    }
    ol:not(.carousel-indicators){
      list-style: decimal;
      margin-left: 1.5em;
    }
  }
  footer.footer {
    ul {
      list-style: none !important;
      margin-left: 0px !important;
    }
  }
  .join-study {
    // Extra Small
    a.btn.btn-sm {
      i.fab.fa-google-play {
        width: 20px;
      }
    }
    // Small
    a.btn.btn-:not(.btn-sm, .btn-md, .btn-lg, .btn-xxl) {
      i.fab.fa-google-play, i.fab.fa-apple {
        width: 28px;
      }
    }
  }
}

.alert.announcement {
  background-color: #147EB3;
  border: none;
  border-radius: 0px;
  margin: 0px;
  padding: 22px;
  .container {
    text-shadow: none;
    line-height: 24px;
    button.close {
      color: white !important;
      font-size: 22px;
      padding: 8px 10px;
      opacity: 1;
      font-weight: 100;
    }
    p {
      margin-top: 6px;
      margin-bottom: 0px;
      font-size: 20px;
      color: white !important;
    }
  }
}

.landing .container-fluid.we-cant-without-you-light {
  @extend .bg-primary-color
}
.why-be-part-of,
.join-study,
.how-does-it-work,
.how-does-x-use-my-data,
.is-my-data-secure,
.generic-section
{
  h1 {
    padding-bottom:0;
  }
  p:not(.sub-p) {
    font-size: var(--landing-p-font-size, 22px);
    line-height: var(--landing-p-line-height, 26px);
    font-family: var(--landing-p-font-family, $font-family);
    padding-top: 0;
    margin-top: 15px;
  }
  .sub-p{
    font-size:0.9rem;
    font-weight:600;
  }
  .highlighted{
    color: var(--primary-color);
  }
}

.landing-content {
  ul{
    list-style: unset;
    margin-left: 1.5em;
  }
  ol{
    list-style: decimal;
    margin-left: 1.5em;
  }
}

ul.list-unstyled {
  list-style: none;
  margin-left: 0px;
}

.container-fluid.generic_section {
  a:not(.btn) {
    text-decoration: underline;
  }
}

.banner-item-image {
  max-height: 50px;
}

.panccyst.landing.index,
.egene.landing.index {
  h1 {
    font-size: 48px;
    line-height: 55px;
    font-family: var(--landing-p-font-family, $font-family);
  }
  .join-study p {
    font-size: 28px;
    line-height: 35px;
    font-family: var(--landing-p-font-family, $font-family);
  }
}

.chdinitiative.landing.index,
.chiipbp.landing.index,
.beat-afib.landing.index,
.mhlvalidation.landing.index,
.coviddetect.landing.index {
  .container-fluid.generic_section {
    h1.row.no-gutters {
      line-height: 1.4em;
      font-size: 24px;
      padding: 0 0 8px;
    }
    p.row.no-gutters {
      font-size: var(--landing-p-font-size, 22px);
      line-height: var(--landing-p-line-height, 26px);
      font-family: var(--landing-p-font-family, "Open Sans", sans-serif);
      padding: 0;
      margin-top: 15px;
    }
  }
}

.testimonials-card-component {
  .excerpt-image {
    object-fit: cover;
    z-index: 0;
  }
  .excerpt-text-container {
    z-index: 1;
  }
}

.image-description {
  font-size: 18px;
}

// keep study specific css alphabetical
// beat-afib
.beat-afib.landing.index {
  .col-lg-5,
  .col-lg-7 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .container-fluid.generic_section {
    h1.row.no-gutters {
      font-weight: 700;
    }
    li {
      list-style-type: disc;
      margin-left: 5%;
    }
  }

  @media screen and (max-width: 767px){
    .col-sm-12.col-md-6.d-flex.align-items-center.justify-content-center.py-2{
      order:0;
    }
    .col-sm-12.col-md-6.py-2{
      order:1;
    }
  }
}

// chdinitiative landing style
.chdinitiative.landing.index {
  background: $eureka-gray-bg;
  .container-fluid {
    background: $eureka-gray-bg;
  }
  .container-fluid.generic_section {
    h1.row.no-gutters {
      font-size: 36px;
      font-weight: 600;
    }
  }
  footer.footer.global-footer img {
    max-height: 40px;
  }
  .join-study {
    h1 {
      font-size: 2.1rem;
    }
  }
  p:first-of-type {
    font-size: 18px;
  }

  #watch-video {
    margin: 9px 0 0 4px;

    a {
      color: $eureka-gray;
      font-size: 17px;
      font-weight: 700;
    }
  }
}
.chdinitiative.landing.index,
.chdinitiative.landing.study,
.chdinitiative.landing.team,
.chdinitiative.landing.faqs,
.chdinitiative.landing.contact {
  .we-cant-without-you {
    background: $eureka-gray-bg;
    h1 {
      font-weight: 100;
      font-size: 2rem;
    }
  }
}

// chiipbp landing style
.chiipbp.landing.index,
.coviddetect.landing.index,
.detect.landing.index,
.mhlvalidation.landing.index {
  .landing-content{
    max-width:100%;
    flex: 0 0 100%;
  }
}

// egene landing styles
.egene.landing.index {
  .join-study p {
    font-size: 25px;
  }
}

//enet
.enet.landing.index {
  .main-img {
    width: 100%;
  }
  img.scroll-label-img {
    padding: 0;
    margin: 45px;
    margin-bottom: 0;
    max-width: 140px;
  }
}

//mhlfreeliving
.mhlfreeliving.landing.index{
  @media screen and (max-width: 767px){
    .col-sm-12.col-md-6.py-2{
      .seal-component{
        display:none;
      }
    }
  }
  .col-sm-12.col-md-6.py-2{
    @media screen and (min-width: 490px){
      h1{
        font-size: 2.4rem;
        line-height: 2.6rem;
        font-weight:500;
      }
    }
    p:not(.choppa):after{
      content:"Tap here to get started.";
      display:block;
      padding-top:20px;
    }
  }
}

// panccyst
.panccyst.landing.index {
  p.choppa {
    font-size: 18px;
    line-height: 20px;
    margin: 20px;
    margin-left: 0;
    a {
      cursor: pointer;
      color: var(--primary-color, #55C1E9);
      transition: color 0.5s ease;
    }
  }
}

.we-cant-without-you {
  h1 {
    font-weight: 400;
    padding: 20px;
  }
}

.we-cant-without-you-light {
  h1 {
    font-weight: 400;
    padding: 20px;
    color: var(--light-bg);
  }
}

.main-img {
  max-width: 100%;
}

.news-bar{
  img{
    padding:15px 15%;
    transition: opacity 0.5s ease;
    &:hover{
      opacity: .65;
    }
  }
  @media screen and (max-width:575px){
    .d-flex{
      flex-direction:column;
    }
    img{
      padding:15px;
      max-width:50%;
    }
  }
}

// coviddetect
.coviddetect .text-message {
  color: #F15720;
  font-weight: bolder;
  margin-top: 1em;
}

// covid19
.covid19.landing.index, .covid19.pages.landing {
  .pledge-button-component a {
    padding-left: 50px;
    padding-right: 50px;
  }
  .join-study {
    ul{
      list-style: none;
      margin-left: 0px;
    }
  }
}

// Eat Move Sleep
.eatmovesleep.landing {
  h1 {
    margin-top: 80px;
    font-size: 37px;
    font-weight: 500;
  }

  h5 {
    color: var(--nav-links, $nav-links);
  }

  #whyjoin, #generic_section1, #generic_section2 {
    .landing-content.justify-content-center {
      -webkit-box-pack: unset !important;
      justify-content: unset !important;
    }
  }

  .generic_section {
    h1 {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 0.5em;
      margin-top: 1em;
    }

    p, ol, li, strong {
      font-size: 18px;
    }
  }
  a#whyjoin h5{
    font-size: 2rem;
  }
}

// ConnectOmron
.connectomron.landing.index {
  h1 {
    font-size: 36px;
    font-weight: 400;
  }

  p {
    font-size: 20px;
  }

  .main-img {
    margin-top: 1em;
    align-self: start;
  }
}

// toolstobefit landing style
.toolstobefit.landing.index {
  .generic_section {
    p {
      display: inline;
    }
  }
}

// actsforhcm landing style
.actforhcm.landing.index {
  h1 {
    line-height: 55px;
    font-size: 40px;
    font-weight: 600;
  }

  p {
    font-size: 20px;
    line-height: 28.5px;
  }
}
