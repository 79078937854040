@import '../heh-variables';

.pledge .container {
  h2, h3, h4 {
    color: #424242;
    font-weight: 400;
  }
  h2 {
    font-size: 40px;
  }
  h4, p.support {
    padding-left: .5em;
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    padding-bottom: .3em;
  }
  .pledge-element.row {
    padding-bottom: 0;
    padding-top: 20px;
    section {
      overflow: auto;

      p {
        padding-left: .5em;
        font-size: 16px;
      }
    }
  }
  .row.button-wrapper {
    padding: 0;
  }
}

//egene pledge style
.egene.pledge .container {
  h2 {
    font-size: 33px;
  }
}

// EatMoveSleep
.eatmovesleep.pledge .container {
  .main-color {
    color: var(--primary-color, $primary-color);
  }
}

// actforhcm
.actforhcm.pledge {
  i    { min-width: 44px; }
  i.fa, i.fab, i.fas, i.far, i.fal, i.fad { font-size: 44px; }
}

// HeH 2.0
.heh-2.pledge  {
  i.fa {
    font-size: 44px;
  }
}
