.eatmovesleep.landing.team {
  h2 {
    font-weight: 600;
  }
  p {
    font-size: 20px;
  }
  small {
    display: block;
    font-size: 12px;
  }

  .team-members {
    border-top: 1px solid #7D888D;

    h3 {
      font-weight: 600;
    }
    strong {
      font-weight: 700;
    }
  }

  img#team-img {
    width: 100%;
  }
}
