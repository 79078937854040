@import '../heh-variables';

.eligibility {
  img {
    margin-top: 3em;
  }

  h2 {
    margin-top: 1em;
    font-size: 40px;
    color: var(--black, $black);
    line-height: 50px;
    font-weight: 400;
  }

  .btn {
    font-size: 27px;
    padding: 0.5rem 1rem;
  }

  p {
    font-size: 26.5px;
    line-height: 35px;
    color: var(--black, $black);
    font-weight: 400;

    a {
      color: var(--black, $black);
      font-weight: 600;
    }
  }

  .logout {
    font-size: 22px;
    margin-top: 5em;
    background-color: #D8D8D8;
    border-color: #D8D8D8;
    color: var(--black, $black) !important;
  }
}
.eligibility[data-eligible="false"] {
  .logout {
    margin-top: 1rem;
    margin-bottom: 15rem;
  }
  p {
    padding: 0rem;
    margin: 0rem;
    font-size: 22px;
    line-height: 35px;
    text-align: left;
    color: var(--black, $black);
    font-weight: 350;

  }
  h2 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-top: 0rem !important;
    margin-bottom: .5rem !important;
  }
}

.solars {
  .eligibility {
    p {
      padding: 0rem;
      margin-top: 0rem;
      font-size: 1.5rem !important;
      font-weight: 600;
    }
    h2 {
      font-weight: 600;
      margin-bottom: .5rem !important;
    }
  }
  .eligibility[data-eligible="false"] {
    .logout {
      color: white !important;
      margin-top: 1rem;
      margin-bottom: 15rem;
    }
    p {
      padding: 0rem;
      margin: 0rem;
    }
    h2 {
      font-size: 1.8rem;
      font-weight: 600;
      margin-top: 0rem !important;
      margin-bottom: .5rem !important;
    }
  }
}

.eatmovesleep {
  .eligibility[data-eligible="false"] {
    .logout {
      color: white !important;
      margin-top: 2rem;
      margin-bottom: 13rem;
      background-color: var(--main-color, $main-color);
      font-weight: 600;
      padding-left: 2em;
      padding-right: 2em;

      &:hover {
        background-color: var(--main-color-hover, $main-color-hover);
      }
    }

    p {
      padding: 0rem;
      margin: 0rem;
      font-size: 18px;
      line-height: 22.5px;
      // text-align: left;
      color: var(--primary-text-color, $primary-text-color);

      a {
        color: var(--main-color, $main-color);

        &:hover {
          color: var(--main-color-hover, $main-color-hover);
        }
      }
    }

    h2 {
      // text-align: left;
      font-size: 30px;
      font-weight: 600;
      margin-top: 0rem !important;
      margin-bottom: 1rem !important;
      color: var(--primary-text-color, $primary-text-color);
    }
  }
}

.toolstobefit {
  .eligibility {
    h2, p {
      color: var(--primary-text-color, $primary-text-color);
    }

    h2 {
      font-weight: 700;
      font-size: 30px;
      line-height: 45px;
    }

    p {
      font-size: 18px;
      line-height: 22.5px;

      a {
        color: var(--primary-color, $primary-color);
      }
    }

    a.btn {
      font-size: 25px;
      line-height: 34px;
      padding: .5em 2em;
    }
  }

  .eligibility[data-eligible="false"] {
    h2, p {
      text-align: left;
    }

    .logout {
      color: white !important;
      margin-top: 2rem;
      margin-bottom: 13rem;
      background-color: var(--main-color, $main-color);
      font-weight: 600;
      padding-left: 2em;
      padding-right: 2em;

      &:hover {
        background-color: var(--main-color-hover, $main-color-hover);
      }
    }
  }
}

.actforhcm {
  .eligibility[data-eligible="false"] {
    h2, p {
      color: var(--form-text-color, $form-text-color);
      text-align: left;
    }

    p {
      font-size: 18px;
      line-height: 22.5px;
    }

    p a {
      color: var(--main-color, $main-color);
    }

    .logout {
      color: white !important;
      margin-top: 2rem;
      margin-bottom: 13rem;
      background-color: var(--main-color, $main-color);
      font-weight: 600;
      padding-left: 2em;
      padding-right: 2em;

      &:hover {
        background-color: var(--main-color-hover, $main-color-hover);
      }
    }
  }

}
