/* HeH colors */

$black: #000;

$white: #fff;

$main-color: #EA3569; // HeH pink. Buttons.
$main-color-hover: #EE5C86;

$primary-color: #55C1E9; // HeH blue. Buttons and links.
$primary-color-hover: #4DB4DF;

$secondary-color: #6C757D; // HeH Cancel or Go Back buttons Also the button group radios. Dark gray with blue tint.
$secondary-color-hover: #5A6268;

$dark-bg: #262626; // Pure dark charcoal gray. Not really used but available.

$light-bg: #A0A6A9; // HeH light gray bg color such as on bg logged in screens, accordions and .btn-light buttons.
$light-bg-hover: #e2e6ea;

$primary-text-color: #4B626A; // HeH body text and <a> tags of .btn-light buttons. Dark gray with blue tint.

$nav-links: #495057; // HeH nav links.

$form-text-color: #495057; // HeH text input color. Dark gray with blue tint.
$form-placeholder-color: #8B9094;

$floating-window-border: #dee2e6; // HeH border around logged in content containers.

$font-family: 'Open Sans', sans-serif;

/*Eureka*/

$eureka-gray: #7D888D;
$eureka-dark: #414D53;
$eureka-turq: #5AD1D8;
$eureka-purple: #927EE6;
$eureka-nav-gray: #A0A6A9;
$eureka-gray-bg: #F8F8F8D1;


/*Generic*/

$light-bg-generic: #F2F3F3;

/** layout **/
$navbar-height: 64px;
$study-web-banner-height: 74px;
$eureka-footer-height: 206px;
$my-footer-height: 123px;
$negative-margin-top: 74px; // -7rem
$panccyst-footer-height: 186px;
$panccyst-footer-height-ipad: 226px;
$heh-footer-height: 306px;
$heh-footer-height-ipad: 366px;

$panccyst-fill-with-footer:  $navbar-height - $negative-margin-top + $panccyst-footer-height;
$panccyst-fill-with-footer-ipad:  $navbar-height - $negative-margin-top + $panccyst-footer-height-ipad;
$heh-fill-with-footer:  $navbar-height - $negative-margin-top + $heh-footer-height;
$heh-fill-with-footer-ipad:  $navbar-height - $negative-margin-top + $heh-footer-height-ipad;
$fill-without-footer:  $navbar-height - $negative-margin-top;

$gradient-color: $main-color;
$gradient-color-hover: $main-color-hover;

$eureka-fill-with-footer: $navbar-height + $study-web-banner-height + $eureka-footer-height;
$my-fill-with-footer: $navbar-height + $my-footer-height;
$transition-fill-with-footer: $negative-margin-top + $eureka-footer-height;