@import '../heh-variables';

.auth-registrations, .auth-sessions, .auth-passwords, .sign-up-phone, .sign-up-phone-verify, .sign-up-email, .change-your-password, .passwordless-sign-in, .passwordless-confirmation, .authentication-phones-codes, .authentication-phones-verification {
	background-color: #FFF;
	.floating-window{
		max-width:480px;
		margin:0 auto;
		.js-snippet{
			display:none;
		}
		img{
			max-width:140px;
			margin-top:10px;
		}
		.form-group{
			display: flex;
			flex-direction: column;
			position:relative;
			.visible-label{
				position:absolute;
				bottom: 36px;
				padding:1px 5px;
				margin-left:10px;
				font-weight:500;
				color:$eureka-gray;
				background:$white;
			}
			.form-control{
				border: 1px solid $eureka-gray;
				border-radius: 0.15rem;
				box-shadow:0 0 0 0;
				&:focus{
					border-color: var(--primary-color, $primary-color);
					& ~ .visible-label{
						color: var(--primary-color, $primary-color);
					}
				}

			}
			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-webkit-autofill:active  {
			    -webkit-box-shadow: 0 0 0 30px $white inset !important;
			}
			input{
				filter:none;
			}
			.eye-icon{
				position:absolute;
				margin-right: 1.1rem;
				right: 0;
				top: 50%;
				font-size: 1.1rem;
				cursor: pointer;
				color:$eureka-gray;
			}
			.field-valid.form-control{
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2381D135' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
				background-repeat: no-repeat;
				background-position: right calc(.375em + .1875rem) center;
				background-size: calc(.75em + .375rem) calc(.75em + .375rem);
				&:active, &:focus{
					box-shadow:0 0 0 0!important;
				}
			}
			.field-invalid.form-control{
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='none' stroke='%23CE0B24' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
				background-repeat: no-repeat;
				background-position: right calc(.375em + .1875rem) center;
				background-size: calc(.75em + .375rem) calc(.75em + .375rem);
				border-color:#CE0B24;
				&:active, &:focus{
					box-shadow:0 0 0 0!important;
				}
			}
		}
		.custom-control-label{
			font-size:inherit;
		}
		.custom-control{
			padding-left: .5rem;
		}
		.btn-main-color{
			transition: all 0.5s ease;
			&:hover{
				opacity:0.85;
			}
			@media only screen and (min-width:481px){
				padding-left:2.5rem;
				padding-right:2.5rem;
			}
		}
		span, h3, a{
			font-weight:500;
		}
		select{
			height: calc(2.875rem + 2px);
			padding: 0.5rem 1rem;
		}
	}
}

// Eat Move Sleep
.eatmovesleep.registrations.new .container-2,
.eatmovesleep.sessions.new .container-2,
.eatmovesleep.passwords.new .container-2 {
  a {
    color: var(--main-color, $main-color);

    &:hover {
      color: var(--main-color-hover, $main-color-hover);
    }
  }

  .floating-window .form-group .form-control {
    &:focus {
      & ~ .visible-label {
        color: var(--main-color, $main-color);
      }
    }
  }
}

