@import '../../heh-variables';

.profile-measurements-medications.index.modal-open, .activity-assignments.show.modal-open {
  .small-width { max-width: 5em; }
  .medium-width { max-width: 15em; }
  .form-inline span { margin: 5px }
  .ui-autocomplete { z-index: 1500; }
  .hide-list-decoration { list-style-type:none }
  .no-pointer-event { pointer-events: none }
  .input-group-text { background: var(--white, $white); }
}
