.btn.android-show-inline,.android-show{
  display: none;
}
.android-available {
  .android-show-inline{
    display: inline-block;
  }
  .android-show{
    display: block;
  }
}
.btn.ios-show-inline,.ios-show{
  display: none;
}
.ios-available {
  .ios-show-inline{
    display: inline-block;
  }
  .ios-show{
    display: block;
  }
}

