@import '../heh-variables';

.faq {
  a {
    font-weight: bolder;
  }

  li {
    list-style-type: disc;
    margin-left: 5%;
  }
}

// EatMoveSleep
.eatmovesleep.faqs {
  .faq {
    padding-top: 1em;

    p {
      margin-bottom: 3em;
    }
    h5 {
      margin-bottom: 1em;
      font-size: 18px;
      font-weight: bold;
    }
  }
}
