@import '../heh-variables';

form.verification-code-check-form {
  input[type='text'] {
    margin-right: 4px;
    height: 52px;
  }

  input[type='submit'] {
    margin: 0px;
  }
}
