div.picker__frame {
  max-width: 430px;
}

div.picker__nav--prev, div.picker__nav--next{
  height: 2em;
  &:hover{
    background: inherit;
  }
}

select.picker__select--month,
select.picker__select--year{
  padding: 0.5em;
  text-indent: 1px;
  text-overflow: '';
  border: none;
  height:auto;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button.picker__button--close{
  display: none;
}
