.photo-testimonial-card-component{
  .card {
    border: none;
    border-radius: 7px;
  }
  .card.d-lg-block{
    width: 60rem;
    color: white;
    .row{
      .excerpt-bg{
        height: 30rem;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 7px 0px 0px 7px;
        h2, h4 {
          font-weight: 400;
          letter-spacing: .08rem;
        }
      }
      .testimonial-photo{
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 0px 7px 7px 0px;
      }
    }
  }
  .card.d-block{
    .card-body{
      color: white;
      border-radius: 0px 0px 7px 7px;
      height: 20rem;
      .card-text{
        font-weight: 500;
      }
    }
  }
  @media only screen and (max-width: 420px){
    .card.d-block{
      .card-body{
        .hasLongText{
          font-size: 1.5rem;
          line-height: 1.8rem;
          @media (max-width: 340px){
            font-size: 1.2rem;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
}
