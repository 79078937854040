.alert-success {background-color:#E6F1DF; border:1px solid #E1ECD2; color:#59955C; padding:10px 35px 11px 3px;}
.alert-success a {font-family: "proxima_novabold", "Helvetica Neue", Helvetica, Arial, sans-serif; color:#004479;}

.alert-success2 {background-color:#B4DEC1; border:1px solid #B4DEC1; color:#125928; padding:10px 35px 11px 15px;}
.alert-success2 h1 {font-family: "proxima_novabold", "Helvetica Neue", Helvetica, Arial, sans-serif; font-size: 27px; margin:0 0 4px; color:#007742; background: url('app/app/assets/images/app/alert-check_large.jpg') left center no-repeat; padding:8px 0 8px 50px;}
.alert-success2 p{color:#125928; padding-left: 5px; margin:0 0 6px;}
.alert-success2 p.checkalert{margin:0; background: url('app/app/assets/images/app/alert-check_large.jpg') left center no-repeat; padding:12px 0 12px 50px; font-size:16px;}

.alert-error {background-color:rgba(242,121,108,0.6); border:rgba(242,121,108,0.6); color:#591912;}
.alert-error a {color:#591912;}

.alert-info {background-color: #FCD9A5; border: #FCD9A5; color: #593D12; margin-bottom:0px; border-radius:0px; padding:14px 35px 16px 0; font-size:18px; line-height:24px;}
.alert-info a {font-family: "proxima_novabold", "Helvetica Neue", Helvetica, Arial, sans-serif; color:#593D12;}
.alert-info div.container-inside {
  max-width:980px;
  margin:0 auto;
}

.alert{
  padding:15px;
  margin-bottom:0;
  .close{
    margin:0;
  }
}

.profile-measurements .alert{
  margin-bottom:2rem;
}
