.loader-component {
  display: contents;
  .ajax-spinner-wrapper{
    display: flex;
    flex-direction: column;
  }
  .ajax-spinner-wrapper{
    display: contents;
  }
  .ajax-spinner-target{
    height: 15px;
    display: block;
  }
  .ajax-spinner {
    float: left;
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 15px;
    height: 15px;
    float: top;
    display: inline-block;
    animation: spin 2s linear infinite;
  }
  .ajax-spinner-content{
    position: relative;
    top: -20px;
    display: inline-block;
    width: 100%;
    animation: sit 1s linear;
  }
}
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
@keyframes sit {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
