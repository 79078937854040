.activity_complete {
  h1 {
    font-weight: 700;
    font-size: 2em;
  }

  a.take-a-break-button {
    background: #BFCFD6;
    color: black;
    font-weight: 600;
  }
}
