/*** Settings page ***/

.settings {
  .settings_check {
    padding-left: 20px;
    position: relative;

    &:before{
      position: absolute;
      font-family: 'Font Awesome 5 Free';
      top: -1px;
      left: 0;
      content: "\f058";
      color: var(--green, $green);
    }
  }
  .settings_x {
    padding-left: 20px;
    position: relative;

    &:before{
      position: absolute;
      font-family: 'Font Awesome 5 Free';
      top: -1px;
      left: 0;
      content: "\f057";
      color: var(--red, $red);
    }
  }
}

