/*** Global ***/

@import "bootstrap/scss/bootstrap";
@import "./heh-variables";
@import "./surveyor_all";
@import "./animations";
@import "./components/alerts.scss"; @import "./components/buttons.scss"; @import "./components/card.scss"; @import "./components/carousel.scss"; @import "./components/footer.scss"; @import "./components/form/custom_control_input.scss"; @import "./components/form/form_control.scss"; @import "./components/form/medication_form.scss"; @import "./components/form/picker.scss"; @import "./components/navigation.scss"; @import "./components/play-button.scss"; @import "./components/svgs.scss"; @import "./components/tiles.scss";
@import "../../../components/alert_component.scss"; @import "../../../components/animations/loader_component.scss"; @import "../../../components/buttons/share_button_component.scss"; @import "../../../components/call_to_action_component.scss"; @import "../../../components/checkmark_component.scss"; @import "../../../components/crossmark_component.scss"; @import "../../../components/forms/checklist_form_component.scss"; @import "../../../components/gallery_component.scss"; @import "../../../components/mini_card_component.scss"; @import "../../../components/participant_count_component.scss"; @import "../../../components/seal_component.scss"; @import "../../../components/shipping_address_component.scss"; @import "../../../components/social_share_buttons_component.scss"; @import "../../../components/testimonials/card_component.scss"; @import "../../../components/testimonials/photo_card_component.scss"; @import "../../../components/testimonials/photo_card_slider_component.scss"; @import "../../../components/testimonials/slider_component.scss";
@import "./pages/activity_complete.scss"; @import "./pages/download-app-instructions.scss"; @import "./pages/elegibility.scss"; @import "./pages/end_of_study.scss"; @import "./pages/evisit_complete.scss"; @import "./pages/faq.scss"; @import "./pages/fitbit.scss"; @import "./pages/helpanotherway.scss"; @import "./pages/kardia.scss"; @import "./pages/kickoff.scss"; @import "./pages/landing.scss"; @import "./pages/omron.scss"; @import "./pages/pledge.scss"; @import "./pages/pre_docusign.scss"; @import "./pages/react_af.scss"; @import "./pages/registration.scss"; @import "./pages/settings.scss"; @import "./pages/study.scss"; @import "./pages/team.scss"; @import "./pages/verification-codes.scss";
@import "./helpers/cursor.scss"; @import "./helpers/display.scss"; @import "./helpers/filler.scss"; @import "./helpers/nomoretables.scss"; @import "./helpers/scroll.scss"; @import "./helpers/spacing.scss";
@import "./jquery.fancybox.scss";
@import "./bootstrap_legacy";
@import "./controllers/verification_phone.scss"; @import "./controllers/web_phone_banner.scss";

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,700,800&display=swap');

html{
  background:var(--black, $black);
  scroll-behavior: smooth;
  min-height: 100vh;
  background: #FFF;
}

body{
  color: var(--primary-text-color, $primary-text-color);
  font-family:'Open Sans', sans-serif;
  font-weight:300;
}

.main-color{
  color: var(--main-color, $main-color);
}

h1.hero{
  font-size: 3.15rem;
  line-height: 3.65rem;
}

h1, .large-text{
  font-size: 3rem;
  line-height: 3.4rem;
}

.large-text:not(i) {
  font-weight: 300;
}

h2{
  font-size:2.4rem;
  line-height:2.8rem;
  font-weight:300;
}

h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
  font-weight:300;
  line-height:1.4em;
}

.tinytext{
  font-size:0.7rem;
}

.fa, .fab, .fas, .far, .fal, .fad{
  transition: color 0.5s ease;
}

.row{
  padding-top:30px;
  padding-bottom:30px;
}

a {
  cursor: pointer;
  color: var(--link-color, var(--primary-color, $primary-color));
  transition: color 0.5s ease;
  &:not(.btn):hover {
    text-decoration:none;
    color: var(--link-color-hover, var(--primary-color-hover, $primary-color-hover));
  }
  &.btn {
    :not(.btn-outline-primary){
      :not(.btn-light){
        color: var(--white, $white);
      }
    }
    :not(.btn-light){
      :not(.btn-outline-primary){
        color: var(--white, $white);
      }
    }
  }
  &.btn-primary:hover, &.btn-main-color:hover, &btn-secondary:hover {
    color: var(--white, $white);
  }
}

a:hover {
  text-decoration:none;
  color: var(--primary-color-hover, $primary-color-hover);
}

a:hover, a:active, a:focus, fa:active, fa:focus, .btn:focus, .btn.focus, .btn:active, .btn.active, .form-control:focus{
  outline:0;
  box-shadow:none;
}

.btn-group {
  .btn.inactive {
    opacity: 0.6;
  }
}

.align-self-center{
  text-align:center;
}

.no-wrap{
  overflow:hidden;
}

.studies.link {
  p {
    font-size: var(--landing-p-font-size, 22px);
    line-height: var(--landing-p-line-height, 26px);
  }
}

.fw-400 {
  font-weight: 400;
}

p.choppa{
  margin: 10px 0;
}

.vp-player-layout.js-playerLayout{
  left:0;
  right:0;
  width:100%;
}

#fancybox-frame1512768476730{
  width:100%;
}

.second-cta-landing{
  background: var(--primary-color, $primary-color);
  color: var(--white, $white);
  text-align:center;
}

.grey-signup{
  background: var(--light-bg, $light-bg);
  text-align:center;
}

#newslogos{
  background:RGBA(250, 250, 252, 1.00);
  text-align:center;
  padding:25px 0;
}

/*** Icon colors ***/
.eureka-purple-icon {
	color: #927BE9;
}
.eureka-green-icon {
	color: #32A3A8;
}
.eureka-orange-icon {
	color: #F2796C;
}
.eureka-yellow-icon {
	color: #FAC069;
}

/*** Pill colors ***/
.eureka-orange-pill {
	background-color: #F2796C;
}

/*** Transition ***/

#logospot{
  margin: 0 auto 7rem!important;
  padding:0.7rem;
}

#logospot{
  margin: 0 auto 7rem!important;
  padding:0.7rem;
}

.toolstobefit {
  .container-2 {
    #logospot {
      margin: 0;
      padding: 0rem;
    }
  }
}

.container-2{
  background: var(--light-bg, $light-bg);
  > .container{
    margin-top:-7rem;
  }
}

.floating-window{
  background: var(--white, $white);
  padding:2rem;
  border-radius:4px;
}

.floating-window-2{
  background: var(--white, $white);
  padding: 5vw;
  padding-top: 1.5rem;
  padding-bottom: 0;
  padding-right: 3vw;
  border-radius: 2rem;
}

.border{
  border:1px solid var(--floating-window-border, $floating-window-border);
}

/*** Logged in ***/

.bg-light{
  background-color:  var(--light-bg, $light-bg)!important;
}

.user .container-fluid{
  background: $eureka-gray-bg;
}

.display-name{
  right:0;
  bottom:2.1rem;
  width: calc( 100vw / 2);
}

.survey_tn p{
  margin-bottom:.25rem;
  color: var(--white, $white);
  font-size:14px;
}

.pie_chart, .follow-up{
  position: relative;
  top: 1.5rem;
  margin: 0 auto;
  display: block;
  text-align: center;

  .fa.fa-check-circle{
    color: var(--white, $white);
    font-size:5.5em;
    padding-top:.125em;
  }
}

/*** tasks, drilldowns ***/

.modal-body{
  ol li{
    list-style:normal;
  }
}

.container.border.rounded{
  background: var(--light-bg, $light-bg);
  min-height: 88px;

  div[class|="col-md"]{
    border-left:1px solid var(--floating-window-border, $floating-window-border);
  }

  span.task-unit{
    font-size:2.8rem;
    line-height:3.4rem;
    @media screen and (max-width: 489px){
      font-size:2.3rem;
    }
  }
}

.small-text{
  font-size:0.8rem;
  letter-spacing:1px;
}

.min-tile-height{
  min-height:250px;
  @media only screen and (max-width:991px){
    min-height:auto;
  }
}

.min-224{
  min-height:224px;
  @media only screen and (max-width:991px){
    min-height:auto;
  }
}

#drill_down_success{
  margin-bottom:1rem;
}

.profile-measurements .alert{
  margin-bottom:2rem;
}

#no-more-tables{
  .fas.fa-pencil-alt{
    color:$primary-color;
    &:hover{
      color:$primary-color-hover;
    }
  }
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background-color: var(--primary-color, $primary-color);
}

.nav-item a{
  cursor:pointer;
}

ul.ui-autocomplete{background:$light-bg; padding:10px; list-style:none; margin-top:170px!important; max-height:210px; max-width:350px; overflow-y:scroll;}
li.ui-menu-item{font-size:15px; line-height: 20px;}

.consents-approvals {
  .floating-window ul {
    list-style: initial;
    padding-left: 40px;
  }
}

/*** Integration profile tiles ***/

#consent-modal{
	img{
		display:block;
		padding-bottom:10px;
	}
}

.profile_items.connected_devices, .profile_items.connected_apps{
  @extend .row;
  > div{
    @extend .col-lg-4;
  }
}

.profile_item_empty{
  color: var(--primary-text-color, $primary-text-color);
}

.card-body a.btn.btn-primary{
  &:hover{
    color: var(--white, $white);
  }
}


/*** User layout ***/

img.top-image {
  width: 100%;
}

.header {
  margin-top: 1em;
  color: var(--primary-text-color, $primary-text-color);
  font-size: 36px;
  font-weight: 600;
  line-height: 45px;
  text-align: center;
}

.rectangle {
  margin: 2em 0;
  padding: 1em;
  background-color: var(--black, $black);
  color: var(--white, $white);
  font-size: 28.5px;
  font-weight: bold;
  line-height: 35px;

  a {
    color: var(--white, $white);

    &:hover {
      color: var(--primary-color, $primary-color);
    }
  }
}

.baselineprogress.progress.active .progress-bar {
    transition: none !important;
}
.progress-bar{
  background-color: var(--primary-color, $primary-color);
}
.font-weight-bold-1{
  font-weight: 400;
}
.font-weight-bold-2{
  font-weight: 500;
}
@import 'responsive';

.vh-80{
  min-height: 80vh !important;
}

.styled-inside {
  list-style: inside !important;
}

.object-fit-cover {
  object-fit: cover;
}

.rounded-xl {
  border-radius: 0.6rem !important;
}

// Trix Formatted CSS
centered-div {
  display: block;
  text-align: center;
}
